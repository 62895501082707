import { mergeWith } from 'lodash';
import log from './log';

export function resolveDefault (...args) {
  const customizer = (a, b) => {
    return b === null ? a : undefined;
  };

  return mergeWith.apply(null, [...args, customizer]);
}

export {
  log
};
